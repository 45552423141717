module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-KRNJLN877V"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Nightingale-Bamford School","short_name":"Nightingale","start_url":"/","background_color":"#1171b1","theme_color":"#1171b1","display":"minimal-ui","icon":"src/images/favicon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"4db8a3af958cb051caf35164884428b3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
